@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

html {
  scroll-behavior: smooth;
  font-family: 'Merriweather', serif;
}

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

.scroll_nav {
  height: 50px;
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  transition-timing-function: ease-in;
  transition: 0.5s;
  background-color: #fff;
  transform: translateY(-100%);
  z-index: 2;
}

.scroll_nav .link {
  font-size: 1em;
}

.scroll_nav.nav-bar--show {
  transform: translateY(0%);
}

header {
  text-align: center;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("./img/Pattern.svg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-size: 50%;
  background-color: #eeeeee;
}

nav {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

header .nav-btn {
  position: absolute;
  top: 50px;
  right: 50px;
}

header .nav-btn {
  visibility: hidden;
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0;
}

.open {
  z-index: 0;
}

.Xmark {
  transition: 1s;
}

.Xmark:hover {
  color: #b9882d;
  transform: rotate(180deg);
}

@media only screen and (max-width: 1024px) {
  header {
    background-size: 100%;
  }

  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  nav {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    transition: 1s;
    transform: translateY(-100vh);
    background-color: rgba(355, 355, 355, 1);
    z-index: 2;
    background-image: url("./img/PatternDropDown.svg");
    background-size: 150%;
    font-size: 2em;
  }

  header .responsive_nav {
    transform: none;
  }

  .logo_nav {
    width: 0%;
    visibility: hidden;
    display: none;
    opacity: 0;
  }

  .logo {
    width: 70%;
  }

  .scroll_nav {
    justify-content: none;
  }

  .scroll_nav .link {
    padding: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  .logo_nav {
    width: 60%;
  }

  .logo {
    width: 40%;
    visibility: hidden;
    display: none;
    opacity: 0;
  }
}

.link {
  font-size: 1.5em;
  padding: 0px 40px;
  text-decoration: none;
  color: #000;
  transition: 1s;
  stroke: #000 1px 1px solid;
}

.link:hover {
  color: #f3b440;
}

.active {
  color: #b9882d;
  font-weight: bold;
  cursor: pointer;
}

.book {
  margin: 40px 0px;
}

.book_link {
  padding: 15px 20px;
  border: #000 1px solid;
  text-decoration: none;
  color: #000;
  transition: 0.5s;
  font-size: 1.2em;
}

.book_link:hover {
  background-color: #000;
  text-decoration: none;
  color: #fff;
}

.services {
  height: 100%;
  background-color: #eba523;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}

.service_cards {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
  justify-content: center;
  align-content: center;
}

.service_cards .card {
  background-color: #eeeeee;
  width: 350px;
  height: 200px;
  padding: 20px;
  transition: 0.5s;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.service_cards .card:hover {
  transform: scale(1.05);
  background-color: #000;
  color: #eba523;
  background-image: url("./img/Pattern.svg");
  background-size: 200%;
}

.card .heading {
  text-align: center;
  font-size: 1.5em;
}

.card .desc {
  text-align: center;
  padding: 0 50px;
}

.uno_color {
  color: #f3b440;
  transition: 0.5s;
}

.service_cards .card:hover .uno_color {
  color: #fff;
}

.hst {
  font-size: 1.5em;
  margin-top: 50px;
}

@media only screen and (max-width: 1024px) {
  .service_cards {
    display: grid;
    grid-template-columns: 20fr 20fr;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 800px) {
  .service_cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
}

.policys {
  display: flex;
  justify-content: center;
  height: 50vh;
  padding: 30px 25px;
  background-color: #eeeeee;
}

.policys .heading {
  text-align: center;
  font-size: 4em;
}

.policy_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.policy_info p {
  width: 50%;
  padding: 20px;
  font-size: 1.1em;
}

@media only screen and (max-width: 1024px) {
  .policys .heading {
    text-align: center;
    font-size: 2.5em;
  }

  .policy_info p {
    width: 90%;
    padding: 20px;
    font-size: 1.1em;
  }
}

@media only screen and (max-width: 800px) {
  .policys .heading {
    text-align: center;
    font-size: 2em;
  }

  .policy_info p {
    width: 100%;
    padding: 15px;
    font-size: 1em;
  }
}

.contact {
  padding: 10%;
  background-color: #111111;
}

.contact h1 {
  color: #fff;
  text-align: center;
  font-size: 3em;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%;
}

.contact_form p {
  padding: 10px 0;
  color: #eeeeee;
}

.contact_form .input {
  border-radius: 5px;
  padding: 10px;
  border: none;
}

.message {
  border-radius: 5px;
  padding: 10px;
  border: none;
}

.submit {
  border-radius: 5px;
  margin: 15px 0;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.submit:hover {
  font-weight: bold;
  background-color: #eba523;
}

@media only screen and (max-width: 1024px) {
  .contact_form {
    width: 80%;
  }
}

@media only screen and (max-width: 800px) {
  .contact_form {
    width: 90%;
  }
}

footer {
  background-color: #eeeeee;
  background-image: url('./img/Pattern.svg');
  background-size: 25%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer_logo {
  width: 5%;
}

@media only screen and (max-width: 800px) {
  .footer_logo {
    width: 25%;
  }
}